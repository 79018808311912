.pvinstaller-preloader-container {
    height: 245px;
    display: flex;
    align-items: center;
}

.pvinstaller-delete-preload-container {
    height: 158px;
    display: flex;
    align-items: center;
}

.export-installers-btn {
    line-height: 12px !important;
    font-size: 0.9em !important;
    text-transform: capitalize;
}
