.colorPicker {
  .modalContainer {
    min-width: 379px;
    height: 435px;

    .modal-dialog {
      border-radius: 18px;

      .modal-content {
        border-radius: 18px;
        border: 0px solid transparent;
      }
    }
  }

  .color-picker-modal {
    background-color: #25272d;
    color: #fff;
    height: 435px;
    border-radius: 18px;

    .color-picker-header {
      display: flex;
      padding: 20px 30px 0 30px;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 400;

      .check-icon {
        height: 30px;
        width: 30px;
        cursor: pointer;

        path {
          fill: #D52329
        }
      }

      .close-icon {
        height: 20px;
        width: 20px;
        cursor: pointer;

        path {
          fill: #ffffff7f;
        }
      }
    }

    .color-picker-area {
      display: flex;
      justify-content: center;
      border-radius: 12px !important;
      margin-top: 15px;
    }

    .color-picker-controllers {
      display: flex;
      justify-content: space-between;
      margin: 25px 30px 0px 30px;

      input {
        background-color: transparent;
        width: 100px !important;
        display: flex;
        color: #fff;
        justify-content: center;
        border: none !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
        font-size: 16px;
        text-align: start !important;
        border-radius: 0px !important;
        padding-left: 18px !important;
        padding-top: 8px !important;
        cursor: pointer;
      }

      button {
        background-color: transparent;
        display: flex;
        color: #fff;
        justify-content: center;
        border: none;
        align-items: center;
        cursor: pointer;
        width: 82px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        position: relative;
        margin-right: 15px;

        svg {
          position: absolute;
          right: -8px;
          top: 0px;
          width: 40px;
        }

        span {
          bottom: 2px;
          position: absolute;
          display: flex;
          left: 0px;
          font-size: 16px;
        }
      }

      .hex-controller {
        display: flex;
        position: relative;

        span {
          position: absolute;
          top: 10px;
          color: rgba(255, 255, 255, 0.3);
        }
      }

      .rgb-controller {
        display: flex;
        position: relative;
        gap: 10px;
        color: #fff !important;
        margin-top: 10px;

        input {
          width: 40px !important;
          font-size: 16px;
          padding: 0px !important;
        }
      }
    }
  }

  .react-colorful {
    width: 319px !important;
    height: 273px !important;

    &__hue-pointer,
    &__alpha-pointer,
    &__saturation-pointer {
      width: 23px !important;
      height: 23px !important;
      border: 4px solid #fff !important;
    }

    &__hue,
    &__alpha {
      height: 18px !important;
      border-radius: 50px !important;
    }

    &__saturation {
      border-radius: 7px !important;
      width: 319px !important;
      height: 233px !important;
      border-bottom: 0px !important;
    }

    &__last-control {
      margin-top: 20px !important;
    }
  }
}

@media (max-width: 910px) {
  .colorPicker {
    overflow-y: scroll !important;
  }
}

@media (min-width: 827px) {
  .colorPicker {
    overflow-y: auto !important;
  }
}
