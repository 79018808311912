.td-info-email {
  letter-spacing: 0.48;
  color: var(--primary-color) !important;
  cursor: pointer;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.td-info-status {
  border: none !important;
  font-size: 15px !important;
  margin-left: 15px !important;
}
