.usr-default-avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  line-height: 3rem;
  text-align: center;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #3fc5dd;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.usr-default-avatar.medium {
  width: 70px;
  height: 70px;
  line-height: 70px;
}

.usr-default-avatar.big {
  width: 135px;
  height: 135px;
  line-height: 156px;
  font-size: 80px;
  font-weight: 400;
}

.usr-default-avatar.small {
  min-width: 39px;
  width: 39px;
  height: 39px;
  line-height: 39px;
  font-size: 24px;
  font-weight: 400;
}

.company-logo {
  width: 156px;
  height: 56px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.logo-default {
    color: #d8d9da;
    text-align: center;
    line-height: 45px;
    border: 2px solid #d8d9da;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}