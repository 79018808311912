.rt-td.sm_id {
  font-weight: 300;
  color: var(--main-bg-color) !important;
}

.rt-td {
  color: var(--main-bg-color) !important;
  font-weight: 500;
  letter-spacing: 0.48px;
  font-size: 16px;
  line-height: 2.5;
}

.action-buttons {
  text-align: right;
}

.preloader-container {
  height: 322px;
  display: flex;
  align-items: center;
}


.table-details {
  background-color: #f2f2f2;
  padding: 40px 0 19px 26px;
  position: relative;
}

.table-details.expanded-row {
  padding-top: 10px !important;
}

.table-row-details-head {
  color: rgba(44, 46, 53, 0.5) !important;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.48px;
  padding-bottom: 10px;
}

.table-row-details-td {
  color: var(--main-bg-color);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.48px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-row-details-td.email-detail-table {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: var(--primary-color);
  letter-spacing: 0.48px;
  width: 100%;
}

.email-detail-table:hover {
  text-decoration: underline;
}

.dashboard-link {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  top: 34px;
  left: 88%;
}

.error_status_yes {
  font-size: 16px;
}

.acc_status-td {
  font-weight: 400 !important;
}

.la.la-info-circle {
  margin-right: 3px;
  margin-left: 3px;
}

.btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst.btn-comp {
  line-height: 1.5;
  background: transparent;
  border: 1px solid var(--primary-color) !important;
  border-radius: 19px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  box-shadow: none !important;
}

.last_login-td {
  font-weight: 400 !important;
}

.full_name-avatar-td {
  margin-right: 15px;
}

.email-text-td a {
  color: var(--primary-color) !important;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.48px;
}

.phone_num-td,
.city-td {
  font-weight: 400;
}

.rt-th {
  position: relative;
}

.rt-th input[type="checkbox"] {
  position: absolute;
  top: 19px;
  left: 9px;
}

.white {
  color: #fff;
}

.disabled {
  opacity: 0.5;
}

.m-form .m-form__group.radio-oem label {
  font-size: 14px !important;
}

.top-control-tab {
  grid-row-gap: 10px;
}