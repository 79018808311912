.invited-users-wrapper {
  .rt-noData.customNoData {
    top: 95px;
    left: calc(50% + 15px);
  }

  .rt-table {
    background: #fff;
  }

  .react-tabs__tab-list {
    background-color: transparent;
    border: none;

    .m-tabs-line.nav.nav-tabs .nav-link {
      color: #FFFFFF80;
    }
  }
}
